import { ReadonlyURLSearchParams } from "next/navigation";
import UrlQueryUtils from "../utils/UrlQueryUtils";
import { authHttpKey } from "@/lib/Constants";
import { Configurators } from "@/common/entities/configurators/Configurator";

export const doGet = async <T>(url: string, params?: Record<string, string>): Promise<T> => {
    if (params) {
        url = url + "?" + new URLSearchParams(params);
    }

    const res = await fetch(url, {
        cache: "no-cache",
        headers: {
            "x-auth-http-key": btoa(authHttpKey),
        },
    });
    return getResponse(res);
};

export const doPost = async <T>(url: string, data?: any): Promise<T> => {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "x-auth-http-key": btoa(authHttpKey),
        },
        body: JSON.stringify(data),
    });

    return getResponse(response);
};

export const doPut = async <T>(url: string, data?: any): Promise<T> => {
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-http-key": btoa(authHttpKey),
        },
        body: JSON.stringify(data),
    });

    return getResponse(response);
};

export const getResponse = async (res: any): Promise<any> => {
    if (res.ok && (res.status === 200 || res.status === 201) && res.body) {
        return await res
            .json()
            .then((response: any) => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    }

    return undefined;
};

export const getActiveFilters = (searchParams: ReadonlyURLSearchParams): { [key: string]: string | undefined } => {
    const filters: { [key: string]: string | undefined } = {};
    const search = UrlQueryUtils.getSearchParamValue(searchParams, "search");
    if (search) filters.search = search;

    const searchModel = UrlQueryUtils.getSearchParamValue(searchParams, "searchModel");
    if (searchModel) filters.searchModel = searchModel;

    const siteCategoriesIds = UrlQueryUtils.getSearchParamValue(searchParams, "siteCategoriesIds");
    if (siteCategoriesIds) filters.siteCategoriesIds = siteCategoriesIds;

    const siteCategoryParentId = UrlQueryUtils.getSearchParamValue(searchParams, "siteCategoryParentId");
    if (siteCategoryParentId) filters.siteCategoryParentId = siteCategoryParentId.toString();

    const categoriesIds = UrlQueryUtils.getSearchParamValue(searchParams, "categoriesIds");
    if (categoriesIds) filters.categoriesIds = categoriesIds;

    const characteristics = UrlQueryUtils.getSearchParamValue(searchParams, "characteristics");
    if (characteristics) filters.characteristics = characteristics;

    const intervals = UrlQueryUtils.getSearchParamValue(searchParams, "intervals");
    if (intervals) filters.intervals = intervals;

    const tagsIds = UrlQueryUtils.getSearchParamValue(searchParams, "tagsIds");
    if (tagsIds) filters.tagsIds = tagsIds;

    return filters;
};

export const getActiveSorting = (searchParams: ReadonlyURLSearchParams): { [key: string]: string | undefined } => {
    const filters: { [key: string]: string | undefined } = {};
    const orderBy = UrlQueryUtils.getSearchParamValue(searchParams, "orderBy");
    const orderDirection = UrlQueryUtils.getSearchParamValue(searchParams, "orderDirection");
    if (orderBy && orderDirection) {
        filters.orderBy = orderBy;
        filters.orderDirection = orderDirection;
    }
    return filters;
};

export const getConfiguratorImage = (configurator: Configurators) => {
    if (!configurator || !configurator.configuratorImages?.length) {
        return undefined;
    }

    return configurator.configuratorImages[0]?.image?.mediumSizeFileName || configurator.configuratorImages[0]?.image?.originalFileName;
};
